import React, { useEffect, useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import * as Yup from "yup";

import { useTranslation } from "react-i18next";

import Select from "react-select";

import { selectStyles } from "../selectStyles";

import "./style.css";
import { useFormik } from "formik";
import { retrunLanguage } from "../../../../utils/languageSelect";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { generateGuestsOptions } from "../../../../utils/generateGuestsOptions";
import { formatDateComponents } from "../../../../utils/dateTimeHandler";

const reserveFormSchema = Yup.object().shape({
  branchId: Yup.string().required("Branch is required"),
  guests: Yup.string().required("Guests selection is required"),
  reservationDate: Yup.string().required("Select a date for your reservation"),
  reservationTime: Yup.string().required(
    "Select the time for your reservation"
  ),
  seating: Yup.string().required("Seating area is required"),
});

const reserveAuthFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "First name must contain only alphabets")
    .required("First name is required"),
  lastName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Last name must contain only alphabets")
    .required("Last name is required"),
  mobile_no: Yup.string()
    .matches(/^\d{8}$/, "Mobile number must be exactly 8 digits")
    .required("Mobile number is required"),
  mobile_country_code: Yup.string().required("Country code is required"),
  ...reserveFormSchema.fields,
});

const ReserveForm = (props) => {
  const { t, i18n } = useTranslation("dinein");

  const {
    setDineinDateTimeSelector,
    setIsPickerType,
    date,
    time,
    restaurant_id,
    isModal,
    existingReservationInfo,
    handleReservation,
    isLoggedIn,
    branches,
    isEdit,
    handleCancelEdit,
    handleUpdateReservation,
    setDayObject,
    userInfo,
  } = props;

  const branchesInfo = useSelector((state) => state.dinein.dineinBranchesInfo);
  const [selectedBranch, setBranch] = useState();
  const countryCodeOptions = [{ value: "965", label: "+965" }];

  const seatingArea = [
    { value: "any", label: "Any" },
    { value: "inside", label: "Inside" },
    { value: "outside", label: "Outside" },
  ];

  const branchList = Array.isArray(branches) && Array.isArray(branchesInfo)
  ? branches
      .filter((branch) => {
        const matchingBranchInfo = branchesInfo?.find(
          (info) => info.branch_id === branch.id
        );
        return (
          matchingBranchInfo &&
          (matchingBranchInfo.dinein_type === "both" ||
            matchingBranchInfo.dinein_type === "reservation")
        );
      })
      .map((branch) => ({
        value: branch?.id,
        label: retrunLanguage(i18n) ? branch.name_ar : branch.name_en,
      }))
  : [];

  const guests = generateGuestsOptions(selectedBranch?.total_guest_allowed);

  const width = "100%";

  const formik = useFormik({
    initialValues: {
      firstName: existingReservationInfo?.first_name || userInfo?.first_name,
      lastName: existingReservationInfo?.last_name || userInfo?.last_name,
      mobile_no: existingReservationInfo?.mobile_no || userInfo?.mobile_no,
      mobile_country_code:
        existingReservationInfo?.mobile_country_code || "965",
      branchId: existingReservationInfo?.branch_id || "",
      guests: existingReservationInfo?.guest_allowed || "",
      reservationDate: existingReservationInfo?.reservation_date || "",
      reservationTime: existingReservationInfo?.reservation_time || "",
      seating: existingReservationInfo?.sitting_area || "",
      action: "",
    },
    validationSchema: isLoggedIn ? reserveAuthFormSchema : reserveFormSchema,
    onSubmit: (values) => {
      const { action, ...rest } = values;

      const reservationInfo = {
        ...rest,
        restaurant_id,
      };

      if (action === "reserve") {
        handleReservation(reservationInfo);
        // handle joinQueue action
      } else if (action === "save") {
        // handle save action
        const reservationInfoForUpdate = {
          ...reservationInfo,
          reservation_id: existingReservationInfo?.reservation_id,
        };
        handleUpdateReservation(reservationInfoForUpdate);
      }

      formik.setFieldValue("action", "");
    },
    enableReinitialize: true,
  });

  const setBranchInfo = (branchId) => {
    const branchInfo = branchesInfo?.find((item) => item.branch_id === branchId);
    setBranch(branchInfo);
  };

  useEffect(() => {
    if (date) {
      formik.setFieldValue("reservationDate", dayjs(date).format("DD MMM YY"));
    }
  }, [date, time]);

  useEffect(() => {
    if (time) {
      formik.setFieldValue(
        "reservationTime",
        dayjs(time).format("h:mm A").toLowerCase()
      );
    } else if (time === "") {
      formik.setFieldValue("reservationTime", "");
    }
  }, [time]);

  useEffect(() => {
    if (formik.values.reservationDate) {
      const dayObject = formatDateComponents(formik.values.reservationDate);
      setDayObject(dayObject);
    }
  }, [formik.values.reservationDate]);

  useEffect(() => {
    if (existingReservationInfo && isEdit && branchesInfo.length) {
      setBranchInfo(existingReservationInfo?.branch_id);
    }
  }, [formik.values.branchId, branchesInfo]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={1.5} className="reservation-form-container">
        {isModal && (
          <Grid item xs={12}>
            <Typography variant="h3">{t("reserveTable")}</Typography>
          </Grid>
        )}
        {isLoggedIn && (
          <Grid item xs={6}>
            <TextField
              fullWidth
              sx={{
                "& .MuiInputBase-root": {
                  height: "36px !important",
                  marginBottom: "0px !important",
                  font: "normal normal bold 12px Titillium Web",
                  backgroundColor: "#FAFAFA",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#959fa8",
                  font: "normal normal bold 12px/28px Titillium Web",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#C0CDD7",
                },
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
              variant="outlined"
              placeholder={t("form.firstName", { ns: "translation" })}
              name="firstName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Grid>
        )}

        {isLoggedIn && (
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder={t("form.lastName", { ns: "translation" })}
              sx={{
                "& .MuiInputBase-root": {
                  height: "36px !important",
                  marginBottom: "0px !important",
                  font: "normal normal bold 12px Titillium Web",
                  backgroundColor: "#FAFAFA",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#C0CDD7",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#959fa8",
                  font: "normal normal bold 12px/28px Titillium Web",
                },
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
              name="lastName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid>
        )}

        {isLoggedIn && (
          <Grid item xs={3}>
            <Select
              placeholder="+965"
              value={countryCodeOptions.find(
                (option) => option.value === formik.values.mobile_country_code
              )}
              onChange={(option) =>
                formik.setFieldValue("mobile_country_code", option.value)
              }
              options={countryCodeOptions}
              defaultValue={countryCodeOptions[0]}
              styles={selectStyles()}
            />
          </Grid>
        )}

        {isLoggedIn && (
          <Grid item xs={9}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder={t("phoneNo")}
              sx={{
                paddingLeft: "10px",
                "& .MuiInputBase-root": {
                  height: "36px !important",
                  marginBottom: "0px !important",
                  font: "normal normal bold 12px Titillium Web",
                  backgroundColor: "#FAFAFA",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#C0CDD7",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#959fa8",
                  font: "normal normal bold 12px/28px Titillium Web",
                },
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
              name="mobile_no"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.mobile_no}
              error={
                formik.touched.mobile_no && Boolean(formik.errors.mobile_no)
              }
              helperText={formik.touched.mobile_no && formik.errors.mobile_no}
              inputProps={{ maxLength: 8 }}
            />
          </Grid>
        )}

        <Grid item xs={7}>
          <Select
            placeholder="Branch"
            value={branchList.find(
              (option) => option.value === formik.values.branchId
            )}
            onChange={(option) => {
              formik.setFieldValue("branchId", option.value);
              setBranchInfo(option.value);
            }}
            options={branchList}
            styles={selectStyles(null, width)}
          />
        </Grid>

        <Grid item xs={5} textAlign={"left"}>
          <Select
            placeholder={t("seating")}
            // defaultValue={seatingArea[0]}
            onChange={(option) => formik.setFieldValue("seating", option.value)}
            value={seatingArea.find(
              (option) => option.value === formik.values.seating
            )}
            options={seatingArea}
            formatGroupLabel={(data) => (
              <div>
                <strong>{data.label}</strong>
              </div>
            )}
            styles={selectStyles(null, width)}
            isDisabled={!selectedBranch}
            // menuIsOpen={true}
          />
        </Grid>

        {isLoggedIn && (
          <Typography className="reservation-event-details">
            {t("eventDetails")}
          </Typography>
        )}

        <Grid item xs={4} sx={{ paddingTop: "8px !important" }}>
          <input
            type="text"
            className={`form-style reservation-form-input`}
            placeholder={t("date")}
            name="date"
            value={formik.values.reservationDate}
            onClick={() => {
              setDineinDateTimeSelector(true);
              setIsPickerType("date");
            }}
            readOnly={true}
          />
        </Grid>

        <Grid item xs={4} sx={{ paddingTop: "8px !important" }}>
          <input
            type="text"
            className={`${
              formik.values.reservationDate === ""
                ? "reservation-form-time-disabled"
                : ""
            } form-style reservation-form-input`}
            placeholder={t("time")}
            name="time"
            value={formik.values.reservationTime}
            onClick={() => {
              setDineinDateTimeSelector(true);
              setIsPickerType("time");
            }}
            readOnly={true}
            disabled={formik.values.reservationDate === ""}
          />
        </Grid>

        <Grid
          item
          xs={4}
          sx={{ paddingTop: "8px !important" }}
          textAlign={"left"}
        >
          <Select
            placeholder={t("guests")}
            value={guests.find(
              (option) => option.value === formik.values.guests
            )}
            onChange={(option) => formik.setFieldValue("guests", option.value)}
            options={guests}
            styles={selectStyles(true, width)}
            isDisabled={!selectedBranch}
            // defaultValue={guests[0]}
          />
        </Grid>

        {!isEdit ? (
          <Grid item xs={12}>
            <Button
              type="submit"
              onClick={() => formik.setFieldValue("action", "reserve")}
              className="reserve"
            >
              {t("reserve")}
            </Button>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            sx={{ display: "flex", alignItems: "center", width: "100%" }}
          >
            <Button className="cancel" onClick={handleCancelEdit}>
              {t("cancel", { ns: "dialog" })}
            </Button>
            <Button
              type="submit"
              className="save-edit"
              onClick={() => formik.setFieldValue("action", "save")}
            >
              {t("save", { ns: "dialog" })}
            </Button>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default ReserveForm;
