import React, { useEffect, useState } from "react";

import { Box, Button, Typography } from "@mui/material";

// import DineInSuccess from "../dineInSuccess";

import { useTranslation } from "react-i18next";

import DineinQueue from "../dineinQueue";

import "./style.css";
import DineinReservation from "../dineinReservation";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import QueueAndReserveInfo from "./queueAndReserveInfo";
import { setActiveButton } from "../../../../store/reducers/resturantsSlice";
import getUserInfo from "../../../../api/customer/getUserInfo";
import getDineInBranchInfo from "../../../../api/restaurant/dineIn/getDineInBranchInfo";
import { setDineinBranchesInfo } from "../../../../store/reducers/dineinSlice";

const DineInCoditionalComponent = () => {
  const { t } = useTranslation("dinein");
  const { id: restaurant_id } = useParams();
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");
  const localUserInfo = useSelector((state) => state.auth.user);
  const activeButton = useSelector((state) => state?.resturants?.activeButton);
  const [activeButtonState, setActiveButtonState] = useState(activeButton);
  const [dineinBranchInfo, setBranchInfo] = useState();

  const [user, setUser] = useState({
    first_name: localUserInfo.first_name,
    last_name: localUserInfo.last_name,
    mobile_no: localUserInfo.mobile,
    mobile_country_code: localUserInfo.mobile_country_code,
  });

  useEffect(() => {
    localUserInfo.id === null &&
      getUserInfo(token).then((res) => {
        if (res.statusCode === 200) {
          const { first_name, last_name, mobile_country_code, mobile_no } =
            res.data;
          const userInfoFromApi = {
            first_name,
            last_name,
            mobile_no,
            mobile_country_code,
          };

          setUser({ ...userInfoFromApi });
        }
      });
  }, [localUserInfo]);

  const handleActiveButton = (dineInType) => {
    setActiveButtonState(dineInType);
    dispatch(setActiveButton(dineInType));
  };

  useEffect(() => {
    getDineInBranchInfo(restaurant_id)
      .then((res) => {
        dispatch(setDineinBranchesInfo(res?.data));
        setBranchInfo(res?.data);
      })
      .catch((e) => console.error(e));
  }, []);


  return (
    <div className="scrollable-container-wrapper">
      <div className="scrollable-container" id="scrollableDiv">
        <QueueAndReserveInfo
          restaurant_id={restaurant_id}
          isQueue={activeButtonState === "queue"}
        />
        <Box sx={{ paddingBottom: "43px", paddingTop: "10px" }}>
          <Box className="dinein-buttons">
            <Button
              className={activeButtonState === "queue" ? "dinein-active" : ""}
              onClick={() => handleActiveButton("queue")}
            >
              {t("queue")}
            </Button>
            <Button
              className={
                activeButtonState === "reservation" ? "dinein-active" : ""
              }
              onClick={() => handleActiveButton("reservation")}
            >
              {t("reservation")}
            </Button>
          </Box>

          <Typography variant="h2" className="dinein-heading-text">
            {activeButtonState === "queue" ? t("joinQueue") : t("reserveTable")}
          </Typography>

          <Box className="dinein-queue-slider">
            {activeButtonState === "queue" ? (
              <DineinQueue
                activeButton={activeButtonState}
                restaurant_id={restaurant_id}
                user={user}
                dineinBranchInfo={dineinBranchInfo}
              />
            ) : (
              <DineinReservation
                activeButton={activeButtonState}
                restaurant_id={restaurant_id}
                user={user}
              />
            )}
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default DineInCoditionalComponent;
