export const selectStyles = (isGuests, width) => {
  return {
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      backgroundColor: state.isSelected
        ? "rgba(189,197,209,.3)"
        : "transparent",
      color: "#000000",
      ":hover": {
        backgroundColor: "rgba(189,197,209,.3)",
      },
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Ensure the portal is on top of other elements
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      border: "none",
      position: "absolute",
      right: "4px",
      top: "8px",
      "& .css-1xc3v61-indicatorContainer": {
        padding: "0px",
      },
      "& .css-15lsz6c-indicatorContainer": {
        padding: "0px",
      },
    }),

    indicatorSeparator: () => ({
      display: "none",
    }),

    control: (baseStyles) => ({
      ...baseStyles,
      zIndex: 1,
      backgroundColor: "#fafafa",
      border: "1px solid #c0cdd7 !important",
      borderRadius: "12px !important",
      padding: "0px 0px 0px 6px",
      width: width ? width : isGuests ? "69px" : "81px",
      minHeight: "36px",
      color: "#000000",
      boxShadow: "none",
      cursor: "pointer",
    }),

    valueContainer: (baseStyles) => ({
      ...baseStyles,
      padding: "0px",
    }),

    placeholder: (baseStyles) => ({
      ...baseStyles,
      color: "#959FA8",
      font: "normal normal bold 12px/28px Titillium Web",
    }),

    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      color: "#000000",
      borderRadius: "8px",
      marginTop: "0px",
    }),

    singleValue: (provided) => ({
      ...provided,
      font: "normal normal bold 12px/28px Titillium Web",
      color: "#000000",
    }),

    menuList: (base) => ({
      ...base,
      maxHeight: isGuests ? "90px" : "",
      // scrollbarWidth: "none",
      "::-webkit-scrollbar": {
        display: isGuests ? "" : "none",
      },
    }),

    group: (base) => ({
      ...base,
      padding: "0px",
    }),

    groupHeading: (base) => ({
      ...base,
      display: "none",
      padding: "0px",
    }),
  };
};
