import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";

import { useTranslation } from "react-i18next";

import ReserveForm from "./reserveForm";
import DatePickerDialog from "../../../dialog/datePickerDialog";
import { DineInConfirm } from "../../../dialog/dineInConfirm";
import ExistingReservation from "../../../dialog/existingReservation";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import createReservation from "../../../../api/restaurant/dineIn/createReservation";
import {
  filterPrevReserveData,
  removeReservationInfoForEdit,
  setDineinReservationInfo,
  setIsEditReservation,
  setUserReservations,
  updateReservationInfo,
} from "../../../../store/reducers/dineinSlice";
import { paths } from "../../../../routes/paths";
import { retrunLanguage } from "../../../../utils/languageSelect";
import getReservations from "../../../../api/restaurant/dineIn/getReservations";
import editReservation from "../../../../api/restaurant/dineIn/editReservation";
import {
  convertDateFormat,
  convertTo24Hour,
  getReservationDateTime,
} from "../../../../utils/helpers";

import "./style.css";
import { isEmpty } from "lodash-es";

const currentDateTime = new Date();

const DineinReservation = ({ activeButton, restaurant_id, user }) => {
  const { i18n } = useTranslation("dinein");
  const token = localStorage?.getItem("token");
  const isLoggedIn = token !== null;

  const userReservations = useSelector(
    (state) => state?.dinein?.userReservations
  );

  const existingReservationInfo = useSelector(
    (state) => state?.dinein?.existingReservationInfo
  );
  const isEditReservation = useSelector(
    (state) => state?.dinein?.isEditReservation
  );

  const branches = useSelector((state) => state?.resturants?.branchHours);

  const [dineinDateTimeSelector, setDineinDateTimeSelector] = useState(false);
  const [isPickerType, setIsPickerType] = useState("date");
  const [reservationInfoConfrim, setReservationInfoConfirm] = useState();
  const [errorReservationDialog, setErrorReservationDialog] = useState(false);
  const [dineInConfirmDialog, setDineInConfirmDialog] = useState(false);
  const [userReservationInfo, setUserReservationInfo] = useState({});

  const [isEdit, setEdit] = useState(isEditReservation);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [dayObject, setDayObject] = useState({
    day: "",
    dayIndex: null,
    date: "",
    month: "",
    monthIndex: null,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    setDineinDateTimeSelector(false);
    setIsPickerType("date");
  };

  const fetchReservations = useCallback(async () => {
    try {
      const res = await getReservations(); // Using your actual fetch function
      if (res?.data && res.data.length > 0) {
        dispatch(setUserReservations(res.data));
      }
    } catch (error) {
      console.error("Failed to fetch reservations:", error);
    }
  }, [dispatch]); // Include dispatch here to adhere to best practices

  const handleCancelEdit = () => {
    if (isEdit) {
      setEdit(false);
      dispatch(setIsEditReservation(false));
      dispatch(removeReservationInfoForEdit());
    }
  };

  const handleUpdateReservation = (reservationInfo) => {
    editReservation(reservationInfo, restaurant_id)
      .then((res) => {
        const formattedDate = convertDateFormat(res.data.reservation_date);

        const reservationDetails = {
          ...res.data,
          reservation_date: formattedDate,
        };
        if (!isEmpty(res.data)) {
          setReservationInfoConfirm(reservationDetails);
          dispatch(updateReservationInfo(reservationDetails));
          dispatch(removeReservationInfoForEdit());
          setDineInConfirmDialog(true);
        }
      })
      .catch((e) => console.error(e));
  };

  const checkValidReservation = ({
    reservationDate,
    reservationTime,
    branchId,
  }) => {
    // Convert provided reservation date and time into a Date object
    const formattedReservationDate = convertDateFormat(reservationDate);
    const newReservationDateTime = convertTo24Hour(
      formattedReservationDate,
      reservationTime
    );

    return userReservations.some((item) => {
      if (
        item.reservation_date === formattedReservationDate &&
        item.branch_id == branchId &&
        item.restaurant_id == restaurant_id
      ) {
        // Parse existing reservation date and time into a Date object
        const existingReservationDateTime = convertTo24Hour(
          item.reservation_date,
          item.reservation_time
        );

        // Calculate the time difference in hours
        const timeDifference =
          Math.abs(newReservationDateTime - existingReservationDateTime) /
          (1000 * 60 * 60); // in hours
        // Return true if the times are the same, less than 2 hours apart, or more than 2 hours apart
        return timeDifference < 2;
      }
      return false;
    });
  };

  useEffect(() => {
    if (userReservations?.length) {
      const filteredReservations = userReservations?.filter(
        (reservation) => reservation.restaurant_id === restaurant_id
      );

      const filteredOldReservations = filteredReservations.filter(
        (reservation) => {
          const reservationDate = new Date(
            reservation.reservation_date.split("-").reverse().join("-")
          );
          return reservationDate >= currentDateTime.setHours(0, 0, 0, 0);
        }
      );

      if (filteredOldReservations?.length) {
        const sortedReservations = filteredOldReservations?.sort((a, b) => {
          const aDateTime = getReservationDateTime(a);
          const bDateTime = getReservationDateTime(b);

          const aDiff = Math.abs(currentDateTime - aDateTime);
          const bDiff = Math.abs(currentDateTime - bDateTime);

          return aDiff - bDiff;
        });

        const userReservationInfo = sortedReservations?.[0] || {};

        setUserReservationInfo(userReservationInfo);
      }
    }
  }, [userReservations]);

  const handleReservation = (reservationInfo) => {
    const sharedState = {
      restaurant_id: +restaurant_id,
      path: location.pathname,
      guest: true,
      filterSelectedNow: "dinein",
      dineinType: "reserve",
      info: {
        branch_id: reservationInfo?.branchId,
        guest_allowed: reservationInfo?.guests,
        reservation_date: reservationInfo?.reservationDate,
        reservation_time: reservationInfo?.reservationTime,
        sitting_area: reservationInfo?.seating,
        restaurant_id: reservationInfo?.restaurant_id,
      },
    };
    if (isLoggedIn) {
      const hasExistingReservation = checkValidReservation(reservationInfo);

      if (!hasExistingReservation) {
        createReservation(reservationInfo, restaurant_id)
          .then((res) => {
            const formattedDate = convertDateFormat(res.data.reservation_date);

            const reservationDetails = {
              ...res.data,
              reservation_date: formattedDate,
            };
            setReservationInfoConfirm(reservationDetails);
            setDineInConfirmDialog(true);

            if (localStorage.getItem("userReserveTemp")) {
              dispatch(filterPrevReserveData(reservationInfo?.branchId));
            }
            localStorage.removeItem("userReserveTemp");

            dispatch(setDineinReservationInfo(reservationDetails));
          })
          .catch((e) => console.error(e));
      } else {
        setErrorReservationDialog(true);
      }
    } else {
      const makeItTrue = true;
      // dispatch(setDineinReservationInfo(reservationInfo));
      localStorage.setItem("userReserveTemp", makeItTrue);

      navigate(paths.phone_verification_index, {
        state: sharedState,
      });
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      if (!userReservations || userReservations.length === 0) {
        fetchReservations(); // Fetch data if needed
      }
    }
  }, [userReservations, fetchReservations]); // Use fetchReservations here

  useEffect(() => {
    setEdit(isEditReservation);
  }, [isEditReservation]);

  return (
    <Box className="reserve-container">
      <ReserveForm
        existingReservationInfo={
          isLoggedIn
            ? isEdit
              ? existingReservationInfo
              : userReservationInfo
            : null
        }
        setDineinDateTimeSelector={setDineinDateTimeSelector}
        setIsPickerType={setIsPickerType}
        date={date}
        time={time}
        restaurant_id={restaurant_id}
        handleReservation={handleReservation}
        isLoggedIn={isLoggedIn}
        branches={branches}
        isEdit={isEdit}
        handleCancelEdit={handleCancelEdit}
        handleUpdateReservation={handleUpdateReservation}
        setDayObject={setDayObject}
        userInfo={user}
      />
      <div
        style={{
          marginTop: "11px",
        }}
      >
        {/* <BranchesCards /> */}
      </div>
      <DatePickerDialog
        dineinDateTimeSelector={dineinDateTimeSelector}
        handleClose={handleClose}
        isPickerType={isPickerType}
        setIsPickerType={setIsPickerType}
        setDineinDateTimeSelector={setDineinDateTimeSelector}
        setDate={setDate}
        setTime={setTime}
        branches={branches}
        dayObject={dayObject}
      />
      <DineInConfirm
        dineInConfirmDialog={dineInConfirmDialog}
        setEdit={setEdit}
        isEdit={isEdit}
        handleClose={() => {
          setDineInConfirmDialog(false);
          handleCancelEdit();
        }}
        branchName={
          branches.find(
            (branch) => branch.id === reservationInfoConfrim?.branch_id
          )?.[retrunLanguage(i18n) ? "name_ar" : "name_en"]
        }
        guestInfo={{
          guestName:
            reservationInfoConfrim?.first_name +
            " " +
            reservationInfoConfrim?.last_name,
          seatingArea: reservationInfoConfrim?.sitting_area,
          numberOfPersons: reservationInfoConfrim?.guest_allowed,
          reservationDate: reservationInfoConfrim?.reservation_date,
          reservationTime: reservationInfoConfrim?.reservation_time,
          phoneNumber: `${reservationInfoConfrim?.mobile_country_code} ${reservationInfoConfrim?.mobile_no}`,
          reservationNo: reservationInfoConfrim?.reservation_no,
        }}
        queueNumber={reservationInfoConfrim?.queue_no}
        activeButton={activeButton}
      />
      <ExistingReservation
        open={errorReservationDialog}
        setOpen={setErrorReservationDialog}
      />
    </Box>
  );
};

export default DineinReservation;
