import React from "react";
import { Box } from "@mui/material";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import DynamicExclaim from "./dynamicExclaim";
import { useTranslation } from "react-i18next";
import { retrunLanguage } from "../../../../utils/languageSelect";
import {
  setIsEditReservation,
  setReservationInfoForEdit,
} from "../../../../store/reducers/dineinSlice";
import { getReservationDateTime } from "../../../../utils/helpers";
import { format } from "date-fns";

function QueueAndReserveInfo({ restaurant_id, isQueue }) {
  const { t, i18n } = useTranslation("dinein");
  const dispatch = useDispatch();

  const token = localStorage?.getItem("token");
  const isLoggedIn = token !== null;

  const userQueueInfo = useSelector((state) => state?.dinein?.userQueueInfo);
  const userReservations = useSelector(
    (state) => state?.dinein?.userReservations
  );
  const currentDateTime = new Date();
  const isSameRestaurantAsQueue = userQueueInfo.restaurant_id == restaurant_id;
  
  // Filter the reservations by matching restaurant_id
  const filteredReservations = userReservations
    ?.filter((reservation) => reservation.restaurant_id == restaurant_id)
    .filter((item) => !!item);

  // Sort the filtered reservations by the closest to the current date and time
  const sortedReservations = filteredReservations
    ?.filter((reservation) => {
      const reservationDateTime = getReservationDateTime(reservation);
      return reservationDateTime > currentDateTime; // Ensure the reservation is after the current time
    })
    .sort((a, b) => {
      const aDateTime = getReservationDateTime(a);
      const bDateTime = getReservationDateTime(b);

      const aDiff = Math.abs(currentDateTime - aDateTime);
      const bDiff = Math.abs(currentDateTime - bDateTime);

      return aDiff - bDiff;
    });

  // Get the reservation with the closest date and time
  const userReservationInfo = sortedReservations?.[0] || {};

  const branches = useSelector((state) => state?.resturants?.branchHours);
  const queueBranch = branches.find(
    (item) => item.id === userQueueInfo.branch_id
  );

  const handleEdit = () => {
    dispatch(setReservationInfoForEdit(userReservationInfo));
    dispatch(setIsEditReservation(true));
  };

  return (
    <div>
      {isLoggedIn
        ? isQueue && isSameRestaurantAsQueue
          ? !isEmpty(userQueueInfo) &&
            userQueueInfo.queue_id && (
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ px: 3, py: 1, backgroundColor: "#F0F6F5" }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ margin: "-3px 5px 0px 0px" }}>
                    <DynamicExclaim fillColor={"#000000"} />
                  </div>
                  <div>
                    <p
                      style={{
                        color: "#000000",
                        fontSize: "12px",
                        margin: "0px 5px 0px 0px",
                      }}
                    >
                      {" "}
                      {`${t("currentQueueAt")}`}
                      <br />
                      {`${queueBranch?.name_en}, ${
                        userQueueInfo?.guest_allowed
                      } ${t("persons")}, ${userQueueInfo?.sitting_area}`}
                    </p>
                  </div>
                </div>
                <span
                  style={{
                    color: "white",
                    height: "35px",
                    width: "35px",
                    backgroundColor: "#036855",
                    borderRadius: "10px",
                    paddingTop: "5px",
                    textAlign: "center",
                  }}
                >
                  {userQueueInfo?.queue_no}
                </span>
              </Box>
            )
          : !isEmpty(userReservationInfo) && userReservationInfo.restaurant_id == restaurant_id && (
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                position={"relative"}
                alignItems={"center"}
                sx={{ px: 3, py: 1, backgroundColor: "#F0F6F5" }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ margin: "-3px 5px 0px 0px" }}>
                    <DynamicExclaim fillColor={"#000000"} />
                  </div>
                  <div>
                    <p
                      style={{
                        color: "#000000",
                        fontSize: "12px",
                        margin: "0px 5px 0px 0px",
                      }}
                    >
                      {" "}
                      {`${t("currentReservationAt")}`}
                      <br />
                      {`${
                        branches.find(
                          (branch) =>
                            branch.id === userReservationInfo?.branch_id
                        )?.[retrunLanguage(i18n) ? "name_ar" : "name_en"]
                      }, ${userReservationInfo?.guest_allowed} ${t(
                        "persons"
                      )}, ${userReservationInfo?.sitting_area}, ${format(
                        userReservationInfo?.reservation_date,
                        "dd MMM yy"
                      )}, ${userReservationInfo?.reservation_time}`}
                    </p>
                  </div>
                </div>
                <span
                  style={{
                    position: "absolute",
                    right: "50px",
                    top: "10px",
                    color: "black",
                    height: "10.5px",
                    width: "10.5px",
                    font: "normal normal normal 12px Titillium Web",
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "underline",
                    cursor: "pointer ",
                  }}
                  onClick={handleEdit}
                >
                  {t("edit", { ns: "common" })}{" "}
                  <img
                    src={"/assets/dinein/editpencil.svg"}
                    alt="edit"
                    style={{ marginLeft: "3px" }}
                  ></img>
                </span>
              </Box>
            )
        : null}
    </div>
  );
}

export default QueueAndReserveInfo;
